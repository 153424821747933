import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const SIGNIN_ROUTE = 'accounts.sign_in';
const NOT_AUTH_ROUTES = Object.freeze([
  SIGNIN_ROUTE,
  'accounts.password',
  'accounts.reset',
  'adp-credentials-status',
  'chatbot',
]);

export default Route.extend({
  // Services
  session: service(),
  store: service(),

  // Methods
  async beforeModel(transition) {
    // Exit if user already login
    // or route without authentication required
    if (
      this.session.userIsLoggedIn ||
      NOT_AUTH_ROUTES.includes(transition.targetName)
    ) {
      return;
    }

    // Check if the user is already
    // authenticated in case coming
    // from cognito route
    await this.session.setSession();

    if (this.session.userIsLoggedIn) {
      return;
    }

    let transitionParams = [SIGNIN_ROUTE];

    if (![SIGNIN_ROUTE, 'index'].includes(transition.targetName)) {
      let loginController = this.controllerFor(SIGNIN_ROUTE);
      loginController.previousTransition = transition;
      transitionParams.push({
        queryParams: {
          // TODO: Should not include messaging or hash the messaging.
          needToSignMessage:
            'You need to sign in or sign up before continuing.',
        },
      });
    }
    this.transitionTo(...transitionParams);
    return;
  },

  model(params, transition) {
    // Skip setting if marketing chatbot demo
    let hasSubdomain = window.location.host.split('.').length >= 3;

    if (transition.targetName === 'chatbot' && !hasSubdomain) {
      return;
    }

    // Load the application settings
    // for the registry
    return this.store.findAll('setting');
  },
});
