import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object'
import { alias, notEmpty, sort } from '@ember/object/computed'

export default Component.extend({
  store: service(),
  router: service(),
  session: service('session'),
  window: service(),
  userIsLoggedIn: alias('session.userIsLoggedIn'),
  currentUser: alias('session.currentUser'),
  customSupportURL: alias('session.currentUser.customSupportURL'),
  hasCustomSupportURL: notEmpty('customSupportURL'),
  isMarketingURL: computed('router.currentURL', function() {
    return this.get('router.currentURL') === '/chatbot';
  }),

  // Provide link to append to navbrand
  homeLink: computed('router.currentURL', function() {
    return this.isMarketingURL ? 'https://workcompass.com' : '/';
  }),

  // Provide current company name if currentUser set
  organizationName: computed('currentUser', function() {
    return this.get('currentUser.organizationName') || 'WorkCompass';
  }),

  /**
   * Engagement survey information to display link
   */
  engagementSurvey: computed('currentUser.engagementSurveyId', function() {
    if(!this.currentUser.isAdmin) {
      return null;
    }

    let data = {
      text: 'Create engagement survey',
      link: '/engagement_surveys/new',
    };

    if(this.currentUser.engagementSurveyId) {
      data.text = 'Manage engagement survey',
      data.link = `/engagement_surveys/${this.currentUser.engagementSurveyId}`;
    }

    return data;
  }),
  /**
   * Leadership survey information to display link
   */
  leadershipSurvey: computed('currentUser.hasLeadershipSurvey', function() {

    if(!this.currentUser.canManageSurvey) {
      return null;
    }

    let data = {
      text: 'Manage leadership survey',
      link: '/leadership_surveys/',
    };

    if(!this.currentUser.hasLeadershipSurvey) {
      data.text = 'Create leadership survey',
      data.link += 'new';
    }

    return data;
  }),
  orderedMultipleChoiceSectionTemplates: sort('publishedMultipleChoiceSectionTemplates', 'multipleChoiceSectionTemplateOrdering'),
  orderedFreeTextSectionTemplates: sort('publishedFreeTextSectionTemplates', 'freeTextSectionTemplateOrdering'),
  init(...args) {
    this._super(...args);
    this.publishedMultipleChoiceSectionTemplates = this.publishedMultipleChoiceSectionTemplates || [];
    this.publishedFreeTextSectionTemplates = this.publishedFreeTextSectionTemplates || [];
    this.multipleChoiceSectionTemplateOrdering = this.multipleChoiceSectionTemplateOrdering || ['ordinal'];
    this.freeTextSectionTemplateOrdering = this.freeTextSectionTemplateOrdering || ['ordinal']
  },
  publishedTemplates(sections, type) {
    return sections.filter((s) => {
      return s.get('type') === type && s.get('isPublished');
    });
  },
  didReceiveAttrs() {
    this._super(...arguments);
    // Initializer
    // Load data
    this.store.findAll('plan-template').then((templates) => {
      if (templates.length > 0) {
        let sections = templates.firstObject.sectionTemplates;
        if (sections) {
          let publishedMultipleChoiceSectionTemplates = this.publishedTemplates(sections, 'MultipleChoice');
          this.set('publishedMultipleChoiceSectionTemplates', publishedMultipleChoiceSectionTemplates);
          let publishedFreeTextSectionTemplates = this.publishedTemplates(sections, 'FreeText');
          this.set('publishedFreeTextSectionTemplates', publishedFreeTextSectionTemplates);
        }
      }
    }).catch(() => {
      return true;
    });
  },
  actions: {
    signOut() {
      this.session.signOut();
    },
    openKB() {
      this.window.open('https://support.workcompass.com/knowledge');
    },
    openSupportForm() {
      this.window.open('https://share.hsforms.com/1DgPT1RNlRauDcmLJ_tOsYw114fg');
    },
  }
});
