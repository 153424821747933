define("frontend/components/wc-display-comments", ["exports", "workcompass-components/components/wc-display-comments"], function (_exports, _wcDisplayComments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _wcDisplayComments.default;
    }
  });
});
