define("frontend/components/item-template-card", ["exports", "organization-settings/components/item-template-card"], function (_exports, _itemTemplateCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _itemTemplateCard.default;
    }
  });
});
