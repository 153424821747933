import Controller from '@ember/controller';
import { computed } from '@ember/object'
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { underscore } from '@ember/string';


/**
 * Recude form data to single object
 * @param {object} data
 * @param {array} param1
 * @returns {object}
 */
function reduceFormData(data, [key, value]){
  let val = value;

  if(key === 'targets') {
    val = {
      email: 'ALL',
      target_type: value,
    };
  }

  if(!Reflect.has(data, key)) {
    data[key] = val;
    return data;
  }

  if(!Array.isArray(data[key])) {
    data[key] = [data[key]];
  }

  data[key].push(val);
  return data;
}

/**
 * Validate form has required
 * parameters and fit data type
 * @param {FormData} formData
 * @returns {object}
 */
function validateForm(formData) {
  let errors = {};

  if(!formData.has('targets')) {
    errors.recipientType = 'One or more recipient type must be selected.';
  }

  if(!formData.has('name')
    || isEmpty(formData.get('name').trim())
    || formData.get('name').length > 255) {
    errors.name = 'Please enter a valid name (max 255 characters).';
  }

  return errors;
}

/**
 * Extract field values
 * from form
 * @param {HTMLElement} form
 */
function buildFormData(form) {
  const formData = new FormData(form);

  // Get disabled element values
  let disabledElements = form.querySelectorAll('[disabled]');

  disabledElements.forEach(element => {
    formData.append(element.name, element.value);
  });

  return formData;
}

export default Controller.extend({
  window: service(),
  failToSend: false,
  errors: {},
  defaultname: computed(function() {
    let today = new Date();
    let dateformat = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return `360° survey ${new Intl.DateTimeFormat('en-EN', dateformat).format(today)}`;
  }),
  templates: computed('model.templates.@each',function() {
    return this.model.templates;
  }),
  // TODO: currently getting user through
  // the route. If creating from navbar
  // create 360°, this should be turn
  // into multi selection.
  subjects: computed(function() {
    return [{
      id: this.model.user.id,
      name: this.model.user.name,
    }];
  }),
  recipients: computed(function() {
    let types = ['Managers', 'Peers', 'Direct Reports'];
    return types.map((type) => {
      return {
        display: type,
        value: underscore(type).toUpperCase()
      }
    })
  }),
  errorName: computed('errors.name', function() {
    return this.errors.name;
  }),
  errorRecipientType: computed('errors.recipientType', function() {
    return this.errors.recipientType;
  }),
  actions: {
    cancel() {
      this.transitionToRoute('users', this.model.user.id);
    },
    submit() {
      this.set('failToSend', false);
      this.set('errors', {});

      let frm = this.window.ownerElement().querySelector('form[name="new_program"]');
      let formData = buildFormData(frm);
      let formErrors = validateForm(formData);

      if(Object.keys(formErrors).length) {
        this.set('errors', formErrors);
        return;
      }

      let data = [...formData].reduce(reduceFormData, {});

      let self =  this;
      this.store
        .createRecord('survey-program', data)
        .save()
        .then((program) => {
          self.transitionToRoute('ember-wc-360.programs', program.id);
        }).catch(() => {
          self.set('failToSend',true);
        });

    },
  }
});
