import ApplicationAdapter from './application';

/**
* This adapter gets the credentials for adp.
*
*
*/

export default ApplicationAdapter.extend({
  getCredentials(model, params) {
    const url = this.buildURL('adp-config') + '/get-credentials';
    return this.ajax(url, 'GET', { data: params });
  },
});
