import Route from '@ember/routing/route';

export default Route.extend({
  model(params) {
    return this.store.findRecord('cycle-status', params.year);
  },
  setupController(controller, model) {
    this.store.findAll('performance-review-cycle').then((cycles) => {
      controller.set('performanceReviewCycles', cycles);
      if(!model.isRange){
        const selectedCycle = cycles.find((cycle) => cycle.id == model.id);
        controller.set('selectedCycle', selectedCycle);
      }
    });

    if (model.isRange) {
      controller.set('showRangeMenu', true);
      controller.set('dateRangeSelected', true);
      controller.set('from', model.from);
      controller.set('to', model.to);
    } else {
      controller.set('showRangeMenu', false);
      controller.set('from', null);
      controller.set('to', null);
    }
    controller.set('model', model);
  }
});
