import ApplicationAdapter from './application';

export default ApplicationAdapter.extend({
  queryRecord() {
    return this.ajax('/api/current-user', 'GET', {});
  },
  forgotPassword(params) {
    return this.ajax('/api/password/forgot', 'POST', { data: params });
  },
  resetPassword(params) {
    return this.ajax('/api/password/reset', 'POST', { data: params });
  },
});
