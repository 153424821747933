define("frontend/components/wc-card", ["exports", "workcompass-components/components/wc-card"], function (_exports, _wcCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _wcCard.default;
    }
  });
});
