import DS from 'ember-data';
import { alias, sort } from '@ember/object/computed';
import { computed } from '@ember/object';

export default DS.Model.extend({
  name: DS.attr('string'),
  year: DS.attr('string'),
  isInProgress: DS.attr('boolean'),
  calendars: DS.hasMany('performance-review-calendar'),
  planningPhase: DS.belongsTo('planning-phase', { inverse: 'cycle' }),
  checkInPhases: DS.hasMany('check-in-phase', { inverse: 'cycle' }),
  reviewPhase: DS.belongsTo('review-phase', { inverse: 'cycle' }),

  startOn: alias('planningPhase.startOn'),
  startMonth: alias('planningPhase.startMonth'),
  startDate: alias('planningPhase.startDate'),
  endOn: alias('reviewPhase.endOn'),
  endMonth: alias('reviewPhase.startMonth'),
  endDate: alias('reviewPhase.startDate'),

  checkInPhasesSorting: ['startOn'],
  sortedCheckInPhases: sort('checkInPhases', 'checkInPhasesSorting'),

  allPhases: computed('planningPhase,sortedCheckInPhases,reviewPhase', function() {
    return [
      this.planningPhase,
      ...this.sortedCheckInPhases,
      this.reviewPhase,
    ];
  }),
});
