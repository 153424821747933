import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { empty, notEmpty } from '@ember/object/computed';

export default Controller.extend({
  textareaText: null,
  isBlank: empty('textareaText'),
  feedbackRequestFound: notEmpty('model'),
  hasComment: notEmpty('model.comment'),
  anyComments: notEmpty('model.orderedCommentsAndRatings'),

  placeholderText: computed('hasComment', function() {
    if (this.hasComment) {
      return 'View your comment below, you can only add one comment…';
    } else {
      return 'Start typing to discuss this rating…';
    }
  }),

  titleText: computed('hasComment', function() {
    if (this.hasComment) {
      return 'View your comment below…';
    } else {
      return 'Start typing to discuss this rating…';
    }
  }),

  actions: {
    saveComment(){
      this.set('model.comment', this.textareaText);
      this.model.save().then(() => {
        this.set('textareaText', null);
      });
    },
    deleteComment(){
      this.set('model.deleteComment', true)
      this.set('model.comment', null)
      this.model.save().then(() => {
        this.set('model.deleteComment', false);
      });
    },
    updateRating(value) {
      this.set('model.rating', value)
      this.model.save();
    }
  }
});
