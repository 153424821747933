import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';

export default Controller.extend({
  store: service(),
  window: service(),
  queryParams: ['reset_password_token'],
  reset_password_token: '',
  resetError: null,
  passwordChangedSuccessfully: false,
  actions: {
    restorePassword(password, confirm_password){
      this.store.createRecord('account', {
        password,
        confirm_password,
        reset_password_token: this.reset_password_token,
      })
      .resetPassword()
      .then(() => {
        this.set('resetError', null);
        this.set('passwordChangedSuccessfully', true);
        later(this, function() {
          this.window.replace('/dashboard');
        }, 1500);
      })
      .catch((error) => {
        this.set('resetError', error.errors[0].detail);
      });
    }
  }
});
