define("frontend/controllers/authentication-options/azure-oauth2", ["exports", "organization-settings/controllers/azure-oauth2"], function (_exports, _azureOauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _azureOauth.default;
    }
  });
});
