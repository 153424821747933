define("frontend/routes/templates", ["exports", "organization-settings/routes/templates"], function (_exports, _templates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _templates.default;
    }
  });
});
