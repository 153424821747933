import DS from 'ember-data';
import { computed } from '@ember/object';
import { sort, empty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Item from './item';

export default Item.extend({
  description:      DS.attr('string'),
  notes:            DS.attr('string'),
  ordinal:          DS.attr('number'),
  otherPartyRating: DS.attr('number'),
  plan:             DS.belongsTo('plan'),
  otherParty:       DS.belongsTo('staff-member'),
  attachments:      DS.hasMany('attachment'),
  comments:         DS.hasMany('comment'),
  changes:          DS.hasMany('change'),
  ratings:                DS.hasMany('rating'),
  staffMemberRating:      DS.belongsTo('rating'),
  staffMember:            DS.belongsTo('staff-member'),
  latestReviewerRating:   DS.belongsTo('rating'),
  latestReviewer:         DS.belongsTo('staff-member'),

  commentsOrdering: [ 'createdAt:desc' ],
  orderedComments:  sort('comments', 'commentsOrdering'),
  ratingsOrdering: [ 'createdAt:desc' ],
  orderedRatings:  sort('ratings', 'ratingsOrdering'),

  commentsAndRatings: computed('orderedComments', 'orderedRatings', function() {
    return this.orderedComments.concat(this.orderedRatings);
  }),
  orderedCommentsAndRatings:  sort('commentsAndRatings', 'ratingsOrdering'),

  labelStatus: service(),
  label: computed('', function() {
    return this.labelStatus.label(this);
  }),
  notYetRated: empty('latestReviewerRating.score'),
});
