import { isEmpty } from '@ember/utils';

/**
 * This class is used to parse a string value to its
 * corresponding data type. The class contains several methods
 * to check the type of the value
 */
export default class ValueParser {
  /** 
   * Constructor method that sets the 'value' property
   * of the class to the input argument 
   */ 
  constructor(value) {
    this.value = value;
  }

  /**
   * Converts the given value to its corresponding data type
   * by utilizing helper methods to determine its type.
   * @returns {*}
   */
  convert() {
    if (['true', 'false'].includes(this.value)) return (this.value === 'true');

    return this.toDate() || this.toNumArray() || this.toNum() || this.value;
  }

  /**
   * Check if value is a Date and return the date object
   * @returns {Date|null}
   */
  toDate() {
    return this.isDate(this.value) ? new Date(this.value) : null;
  }

  /**
   * Check if value is a number array
   * @returns {Number[]|null}
   */
  toNumArray() {
    if (!this.isNumberArray(this.value)) return null;
    
    return this.value.split(',')
      .map(str => parseInt(str));
  }

  /**
   * Converts value to a number, returns null if conversion fails
   *  @returns {Integer|null}
   */
  toNum() {
    return isNaN(parseInt(this.value)) ? null : parseInt(this.value);
    }

  /**
   * Check if the setting's value is a date
   * @param {String} setting
   * @returns {Boolean}
   */
  isDate(value) {
    if (isEmpty(value)) return false;
    // Ensure that valid numbers are not parsed as dates.
    // + attempts to convert String to a number
    if (!isNaN(+value)) return false;

    /**
     * Strips whitespace to avoid misinterpreting
     * non-date strings with spaces as dates.
     */
    const potentialDate = new Date(value.replace(/\s/g, ''));

    return (potentialDate !== 'Invalid Date') && !isNaN(potentialDate);
  }

  /**
   * The method checks if the input value is
   * a string representation of an array of integers.
   * @param {String} value
   * @returns {Boolean}
   */
  isNumberArray(value) {
    return /^\d+(,\d+)*$/.test(value) && value.includes(',');
  }
}
