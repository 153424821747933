import DS from 'ember-data';

export default DS.Model.extend({
  isRange:       DS.attr('boolean'),
  from:           DS.attr('date'),
  to:             DS.attr('date'),
  noPlan:         DS.attr('number'),
  needsApproval:  DS.attr('number'),
  approved:       DS.attr('number'),
  overdue:        DS.attr('number'),
  completed:      DS.attr('number'),
  needsApprovalListOfNames: DS.attr(),
  approvedListOfNames: DS.attr(),
  noPlanListOfNames: DS.attr(),
  overdueListOfNames: DS.attr(),
  completedListOfNames: DS.attr()
});
