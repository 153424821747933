define("frontend/helpers/truncate-text", ["exports", "workcompass-components/helpers/truncate-text"], function (_exports, _truncateText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _truncateText.default;
    }
  });
  Object.defineProperty(_exports, "truncate", {
    enumerable: true,
    get: function get() {
      return _truncateText.truncate;
    }
  });
});
