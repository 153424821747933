define("frontend/helpers/comment-change-date", ["exports", "plans/helpers/comment-change-date"], function (_exports, _commentChangeDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _commentChangeDate.default;
    }
  });
  Object.defineProperty(_exports, "commentChangeDate", {
    enumerable: true,
    get: function get() {
      return _commentChangeDate.commentChangeDate;
    }
  });
});
