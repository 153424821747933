import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default Route.extend({
  session: service('session'),
  store: service(),

  beforeModel() {
    // Redirect to not authorised route
    if (!this.session.currentUser.canCreateUsers) {
      this.transitionTo('errors.not-authorised');
    }
  },

  model() {
    return hash({
      users: this.store.findAll('user'),
      divisions: this.store.findAll('division'),
    });
  },

  async setupController(controller, models) {
    let manager = models.users.findBy('id', this.session.currentUser.id);

    // TODO: manager returns payload without division
    // and/or managed divisions information.
    // API will have to be changed to include those information
    // in order to provide a better user experience
    // by defaulting the division to the current user's division
    let user_params = {
      manager,
    };

    user_params.division = (this.session.currentUser.managedDivisions.length)
      ? this.session.currentUser.managedDivisions.firstObject
      : null;

    let user = this.store.createRecord('user', user_params);

    controller.set('user', user);
    controller.set('users', models.users);
    controller.set('divisions', models.divisions);
  }
});
