import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Route.extend({
  // Service
  ajax: service(),
  session: service(),

   model(params){
    
    // If user login, do nothing
    if(this.session.userIsLoggedIn){
      return;
    }

    let transitionParams = {
      queryParams: {
        origin: 'accounts.reset',
      }
    };

    if(isEmpty(params.reset_password_token)){
      return this.transitionTo('accounts.password', transitionParams);
    }

    let self = this;
    return this.ajax.request(
      `/api/account/validate_password_token?token=${params.reset_password_token}`
    ).catch(function(){
      self.transitionTo('accounts.password', transitionParams);
      return;
    });
  },
});
