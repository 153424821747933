import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default Route.extend({
  store: service(),
  async model(params) {
    let user = null;
    try {
      user = await this.store.findRecord('user', params.user_id);
    } catch (error) {
      if (error.errors && error.errors.some((err) => err.status == 401)) {
        // Redirect to not authorised route if the current viewer is
        // not authorised to access the user's record
        return this.transitionTo('errors.not-authorised');
      }
      
    }

    // Redirect to the 'errors.page-not-found' route if user is not found
    if (!user) return this.transitionTo('errors.page-not-found');

    return hash({
      user,
      users: this.store.findAll('user'),
      divisions: this.store.findAll('division'),
    });
  },

  setupController(controller, models) {
    controller.set('user', models.user);
    controller.set('users', models.users);
    controller.set('divisions', models.divisions);
  }
});
