import DS from 'ember-data';

export default DS.Model.extend({
  name:                      DS.attr('string'),
  password:                  DS.attr('string'),
  confirm_password:          DS.attr('string'),
  reset_password_token:      DS.attr('string'),
  email:                     DS.attr('string'),
  rememberMe:                DS.attr('boolean'),
  pictureURL:                DS.attr('string'),
  accountId:                 DS.attr('number'),
  canViewManagerLinks:       DS.attr('boolean'),
  canViewAdminLinks:         DS.attr('boolean'),
  intercomUserHash:          DS.attr('string'),
  customSupportURL:          DS.attr('string'),
  canAccessOrgSettings:      DS.attr('boolean'),
  canCreateUsers:            DS.attr('boolean'),
  canManageAdminUsers:       DS.attr('boolean'),
  canManageDivisions:        DS.attr('boolean'),
  canManageDivisionGoals:    DS.attr('boolean'),
  canManageSectionNames:     DS.attr('boolean'),
  canManageOrganizationGoals: DS.attr('boolean'),
  canManageTemplates:        DS.attr('boolean'),
  canManagePasswordAuth:     DS.attr('boolean'),
  canManageAdpSetup:         DS.attr('boolean'),
  canManageAuthenticationOptionsAzureOauth2: DS.attr('boolean'),
  canManageUsersSync:        DS.attr('boolean'),
  canManageEngagementSurvey: DS.attr('boolean'),
  canManagePerformanceReviewCalendars: DS.attr('boolean'),
  canManageReportsConfiguration: DS.attr('boolean'),
  canSetupBamboohr:          DS.attr('boolean'),
  canSetupPaylocity:         DS.attr('boolean'),
  canManageThirdParties:     DS.attr('boolean'),
  usesPercentageGoalRatings: DS.attr('boolean'),
  showTeambar:               DS.attr('boolean'),
  organizationName:          DS.attr('string'),
  isStaffMember:             DS.attr('boolean'),
  isDivAdmin:                DS.attr('boolean'),
  isAdmin:                   DS.attr('boolean'),

  // SURVEY
  canManageSurvey:           DS.attr('boolean'),
  // If number above 0, has engagement survey
  // otherwise, null or 0 is no engagement survey
  engagementSurveyId:        DS.attr('number'),
  hasLeadershipSurvey:       DS.attr('boolean'),
  managedDivisions:          DS.hasMany('division', { inverse: 'divisionalAdmins',  defaultValue: [] }),

  forgotPassword(params) {
    const adapter = this.store.adapterFor(this.constructor.modelName);
    return adapter.forgotPassword(this, params);
  },
  resetPassword(params) {
    const adapter = this.store.adapterFor(this.constructor.modelName);
    return adapter.resetPassword(this, params);
  },
});
