import DS from 'ember-data';
import { sort } from '@ember/object/computed';
import Item from './item';

export default Item.extend({
  text:     DS.attr('string'),
  description: DS.attr('string'),
  ordinal:  DS.attr('number'),
  comments: DS.hasMany('comment'),
  plan:     DS.belongsTo('plan'),
  'approved?':      DS.attr('boolean'),

  commentsOrdering: [ 'createdAt:desc' ],
  orderedComments:  sort('comments', 'commentsOrdering'),
});
