import DS from 'ember-data';

export default DS.JSONAPISerializer.extend({
  /**
   * Serialize the JSON payload by wrapping it in an '_jsonapi' key.
   * @returns {Object} The serialized JSON payload wrapped in an '_jsonapi' key.
   */
  serialize() {
    let json = this._super(...arguments);

    return { '_jsonapi': this.removeNullAttributes(json) };
  },

  /**
   * Remove attributes with null values from the JSON object to
   * prevent them from being saved as empty.
   * @param {Object} json - The JSON object containing attributes to transform.
   * @return {Object} - The JSON object with null attributes removed.
   */
  removeNullAttributes(json) {
    json.data.attributes = Object.fromEntries(
      Object.entries(json.data.attributes).filter(([, value]) => value !== null)
    );

    return json;
  },
});
