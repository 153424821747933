import { bool } from '@ember/object/computed';

import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default Service.extend({
  // Services
  store: service(),
  window: service(),

  // Extra Attributes
  currentUser: null,
  previousTransition: null,

  // Computed
  userIsLoggedIn: bool('currentUser'),

  init() {
    this._super(...arguments);
  },

  userLoggedIn(current){
    this.set('currentUser', current);
    this.rollbarCurrentUser();
  },
  userLoggedOut(){
    this.set('currentUser', null);
  },

  async setSession() {
    let account = await this.fetchCurrentUser();
    if(account) {
      this.set('currentUser', account);
    } else {
      this.set('currentUser', null);
    }
  },

  async fetchCurrentUser() {
    return this.store.queryRecord('account', {}).catch(() => {
      // Do nothing
    });
  },

  rollbarCurrentUser() {
    if(!isEmpty(this.get('rollbar'))) {
      this.set('rollbar.currentUser', {
        email: this.get('currentUser.email'),
        id: this.get('currentUser.accountId')
      });
    }
  },

  /**
   * Remove account setup
   * and redirect to account sign_in
   */
  async signOut() {
    try {
      await this.store.peekAll('account')
        .firstObject
        .destroyRecord();
    } catch(ex) {
      // Do nothing as no existing
    }
    this.window.replace('/accounts/sign_in');
  },
});
