import EmberRouter from '@ember/routing/router';
import config from './config/environment';

const Router = EmberRouter.extend({
  location: config.locationType,
  rootURL: config.rootURL
});

Router.map(function() {
  this.route('accounts', function() {
    this.route('sign_in');
    this.route('password');
    this.route('reset');
  });
  this.route('users', function() {
    this.route('new');
    this.route('edit', {
      path: ':user_id/edit'
    });
  });

  this.route('users', {
    path: 'users/:userid',
  }, function() {
    this.route('survey-programs', function() {
      this.route('new');
    });
  });

  this.mount('ember-wc-360', { path: '/' });
  this.mount('plans');
  this.mount('chat');
  this.mount('design-system');
  this.mount('organization-settings');
  this.route('adp-credentials-status');
  this.route('adp-pending');

  this.route('feedback-requests', function() {
    this.route('show', { path: '/:id' });
  });

  this.route('chatbot');

  this.route('insight', function() {
    this.route('status-reports', function() {
      this.route('status-report', { path: '/:year' });
    });
  })

  // Error routes
  this.route('errors', function() {
    this.route('page-not-found');
    this.route('not-authorised');
  });
});

export default Router;
