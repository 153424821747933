import DS from 'ember-data';

export default DS.Model.extend({
  name: DS.attr('string'),
  template: DS.attr('string'),
  subject: DS.attr('string'),
  targets: DS.attr(),
  closable: DS.attr('boolean'),
  closedOn: DS.attr('date'),
});
