define("frontend/helpers/index-to-number", ["exports", "plans/helpers/index-to-number"], function (_exports, _indexToNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _indexToNumber.default;
    }
  });
  Object.defineProperty(_exports, "indexToNumber", {
    enumerable: true,
    get: function get() {
      return _indexToNumber.indexToNumber;
    }
  });
});
