define("frontend/components/rateable-card", ["exports", "workcompass-components/components/rateable-card"], function (_exports, _rateableCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _rateableCard.default;
    }
  });
});
