import Service from '@ember/service';

export default Service.extend({
  isSaving: false,
  startedSave() {
    this.set('isSaving', true);
  },
  finishedSave() {
    this.set('isSaving', false);
  }
});
