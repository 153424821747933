define("frontend/models/rating", ["exports", "plans/models/rating"], function (_exports, _rating) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _rating.default;
    }
  });
});
