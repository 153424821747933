define("frontend/components/wc-kebab-menu", ["exports", "workcompass-components/components/wc-kebab-menu"], function (_exports, _wcKebabMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _wcKebabMenu.default;
    }
  });
});
