import DS from 'ember-data';

export default DS.JSONAPISerializer.extend({
  /**
   * Serialize the JSON object using the parent class's behavior
   * and filter null "client-identifier" attributes.
   * @return {Object} - The serialized JSON object with null
   * "client-identifier" attributes excluded if they are null.
   */
  serialize() {
    let json = this._super(...arguments);

    return this.filterNullClientIdentifier(json);
  },

  /**
   * Filter out null values for the "client-identifier" attribute from the JSON object,
   * preventing it from being included in the resulting object, while allowing other
   * null attributes.
   * @param {Object} json - The JSON object containing attributes to filter.
   * @return {Object} - The JSON object with the "client-identifier" attribute excluded
   * if it's null.
   */
  filterNullClientIdentifier(json) {
    const attributes = json.data.attributes;

    if (attributes['client-identifier'] == null) {
      delete attributes['client-identifier'];
    }

    return json;
  },
});
