import Application from '@ember/application';
import Resolver from './resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';

const App = Application.extend({
  modulePrefix: config.modulePrefix,
  podModulePrefix: config.podModulePrefix,
  Resolver,
  engines: {
    chat: {
      dependencies: {
        services: [
          'store',
        ]
      }
    },
    plans: {
      dependencies: {
        services: [
          'store',
          'session',
          'window',
        ],
        externalRoutes: {
          sign_in: 'accounts.sign_in'
        }
      }
    },
    'organization-settings': {
      dependencies: {
        services: [
          'notifier',
          'registry',
          'rollbar',
          'store',
          'session',
          'window',
        ],
        externalRoutes: {
          sign_in: 'accounts.sign_in'
        }
      }
    },
    'ember-wc-360': {
      dependencies: {
        services: [
          'store',
          'session',
          'window',
        ],
        externalRoutes: {
          sign_in: 'accounts.sign_in'
        }
      }
    }
  }
});

loadInitializers(App, config.modulePrefix);

export default App;
