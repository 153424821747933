define("frontend/models/check-in-phase", ["exports", "organization-settings/models/check-in-phase"], function (_exports, _checkInPhase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _checkInPhase.default;
    }
  });
});
