define("frontend/models/templates-surveys-question", ["exports", "organization-settings/models/templates-surveys-question"], function (_exports, _templatesSurveysQuestion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _templatesSurveysQuestion.default;
    }
  });
});
