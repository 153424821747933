define("frontend/templates/team-bar/staff-member", ["exports", "plans/templates/team-bar/staff-member"], function (_exports, _staffMember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _staffMember.default;
    }
  });
});
