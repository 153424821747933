define("frontend/controllers/reports-configuration", ["exports", "organization-settings/controllers/reports-configuration"], function (_exports, _reportsConfiguration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _reportsConfiguration.default;
    }
  });
});
