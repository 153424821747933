import DS from 'ember-data';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Item from './item';

export default Item.extend({
  description:       DS.attr('string'),
  notes:             DS.attr('string'),
  developmentAction: DS.attr('string'),
  successMeasure:    DS.attr('string'),
  ordinal:           DS.attr('number'),
  plan:              DS.belongsTo('plan'),
  attachments:       DS.hasMany('attachment'),
  comments:          DS.hasMany('comment'),
  commentsOrdering:  [ 'createdAt:desc' ],
  changes:           DS.hasMany('change'),
  orderedComments:   sort('comments', 'commentsOrdering'),

  labelStatus: service(),
  label: computed('', function() {
    return this.labelStatus.label(this);
  }),
});
