define("frontend/models/response-part", ["exports", "chat/models/response-part"], function (_exports, _responsePart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _responsePart.default;
    }
  });
});
