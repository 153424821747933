define("frontend/components/kebab-menu", ["exports", "workcompass-components/components/kebab-menu"], function (_exports, _kebabMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _kebabMenu.default;
    }
  });
});
