define("frontend/helpers/relative-time-from-now", ["exports", "plans/helpers/relative-time-from-now"], function (_exports, _relativeTimeFromNow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _relativeTimeFromNow.default;
    }
  });
  Object.defineProperty(_exports, "relativeTimeFromNow", {
    enumerable: true,
    get: function get() {
      return _relativeTimeFromNow.relativeTimeFromNow;
    }
  });
});
