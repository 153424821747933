define("frontend/models/third-party", ["exports", "organization-settings/models/third-party"], function (_exports, _thirdParty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _thirdParty.default;
    }
  });
});
