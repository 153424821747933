import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default Controller.extend({
  registry: service(),
  store: service(),
  session: service(),
  window: service(),
  invalidEmailOrPassword: false,
  queryParams: ['origin', 'needToSignMessage'],
  origin: null,
  needToSignMessage: null,
  previousTransition: null,
  /**
   * Check if minimum password-auth
   * should be proposed.
   */
  needLogin: computed('registry.auth', function() {
    return this.registry.auth.needLogin
      || isEmpty(this.registry.auth.passwordAuthEnabled) ? true : this.registry.auth.passwordAuthEnabled;
  }),
  /**
   * Retrieve all the authentication
   * configuration
   */
  setting: computed('registry.auth', function() {
    return this.registry.auth;
  }),
  actions: {
    authenticate(email, password, rememberMe){
      this.store.createRecord('account', {
        email,
        password,
        rememberMe,
      }).save()
      .then((currentUser) => {
        this.session.userLoggedIn(currentUser);
        let previousTransition = this.previousTransition || this.get('session.previousTransition');
        if(previousTransition) {
          this.set('session.previousTransition', null);
          previousTransition.retry();
        } else {
          this.window.replace('/dashboard');
        }
      }).catch(() => {
        this.set('invalidEmailOrPassword', true);
        document.getElementById('login-password-input').value = '';
      });
    }
  }
});
