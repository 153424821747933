import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { filter, alias, sort, not, or } from '@ember/object/computed';

export default Controller.extend({
  store: service(),
  window: service(),
  session: service('session'),
  currentUser: alias('session.currentUser'),
  users: null,
  divisions: null,
  failToSave: false,

  /**
   * Computes the managed divisions based on the currentUser's role and permissions.
   * If the currentUser is an admin, all divisions are shown.
   * Otherwise, if the currentUser is a divisional admin, only the divisions they
   * manage are shown.
   * @param {Array<DivisionObject>}} divisions - The array of division objects.
   * @param {Object} session.currentUser - The current user object containing
   * role and permissions.
   * @return {Array<DivisionObject>}} - The array of managed division objects.
   */
  managedDivisions: computed('divisions.[]', 'session.currentUser.{isDivAdmin,isAdmin,managedDivisions}', function() {
    let divisions = []
    if (this.session.currentUser.isAdmin) {
      divisions = this.divisions.map((d) => ({ obj: d, disabled: false }));
    } else if (this.session.currentUser.isDivAdmin) {
      let divIds = this.get('session.currentUser.managedDivisions').map((d) => d.id);
      divisions = this.divisions.filter((d) => divIds
        .includes(d.id))
        .map((d) => ({ obj: d, disabled: !divIds.includes(d.id) }));
    }
    return divisions;
  }),
  photoUrl: computed('user.photo', function() {
    if (document.getElementById('file-field')) {
      let file = document.getElementById('file-field').files[0];
      if (file) {
        return window.URL.createObjectURL(file);
      }
    } else {
      return this.get('user.photo.url');
    }
  }),
  managerList: computed('users.[]', function() {
    return this.users.filter(u => u.get('authorisation') !== 'employee' && u != this.user);
  }),
  managersOrdering: ['name'],
  orderedManagers: sort('managerList', 'managersOrdering'),
  isDivisionalAdmin: computed('user.authorisation', function() {
    return (this.get('user.authorisation') === 'divisional_admin');
  }),
  isAdmin: alias('session.currentUser.isAdmin'),
  isNotAdmin: not('isAdmin'),
  canAssignDivAdmin: or('session.currentUser.{isDivAdmin,isAdmin}'),
  canNotAssignDivAdmin: not('canAssignDivAdmin'),
  canAssignAdmin: alias('session.currentUser.isAdmin'),
  canNotAssignAdmin: not('canAssignAdmin'),
  additionalReviewersList: filter('users', function(u) {
    return u != this.user;
  }),
  additionalReviewersOrdering: ['name'],
  orderedAdditionalReviewers: sort('additionalReviewersList', 'additionalReviewersOrdering'),
  actions: {
    save() {
      if (document.getElementById('file-field')){
        let file = document.getElementById('file-field').files[0];
        this.set('user.photo', file);
      }
      this.user.save().then((user) => {
        this.window.replace(`https://${window.location.hostname}/users/${user.get('id')}`);
      }).catch((error) => {
        this.handleSaveErrors(error);
      });
    },
    cancel() {
      this.window.redirectTo(`/users/${this.get('user.id') || ''}`);
      return false;
    },
    setManager(selected){
      let manager = this.store.peekRecord('user', selected);
      this.set('user.manager', manager);
    },
    setAuthorisation(selected){
      this.set('user.authorisation', selected);
    },
    setDivision(selected){
      let division = this.store.peekRecord('division', selected);
      this.set('user.division', division);
    },
    removePhoto() {
      this.set('user.photo', null);
      this.set('user.deletePhoto', true);
    }
  },

  /**
   * Handles errors that occur during save.
   * @param {Error} error - The error object thrown during save.
   */
  handleSaveErrors(error) {
    this.set('failToSave', true);

    if (error.errors && error.errors.some((err) => err.status == 401)) {
      this.set(
        'failToSaveReason',
        'Unauthorized: You do not have permission to edit this user\'s details. ' +
        'Please contact your administrator for assistance.'
      );
    } else {
      this.set(
        'failToSaveReason',
        'We were unable to create the user. Please try again. ' +
        'If the problem persist, please contact support.'
      );
    }
  }
});
