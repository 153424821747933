import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

const WARNING_TRIGGERS = Object.freeze([
  'accounts.reset',
]);

export default Controller.extend({
  store: service(),
  
  queryParams: ['origin'],

  invalidEmail: false,
  origin: null,
  validEmail: false,
  /* COMPUTED */
  /**
   * Flag to indicate if warning would display
   * if transitioned from somewhere else
   */
  showWarning: computed('origin', function(){
    return WARNING_TRIGGERS.includes(this.origin);
  }),

  actions: {
    resetPasswordPetition(email){
      this.store.createRecord('account', { email: email })
      .forgotPassword()
      .then(() => {
        this.set('validEmail', true);
      })
      .catch(() => {
        this.set('invalidEmail', true);
      });
    }
  }
});
