import Service from '@ember/service';

/**
 * Ensure that link contains domain
 * and if not provide, append it.
 * @param {string}  link    Link to parse
 * @returns {string} URL containing domain
 */
function parseURL(link) {

  let href = '';
  // if start with slash, meaning domain not passed
  if(!(link || '').startsWith(`${window.location.protocol}//`)) {
    href += `${window.location.protocol}//${window.location.hostname}`;
    href += (window.location.port ? `:${window.location.port}` : '');
    href += (link || '').startsWith('/') ? '' : '/';
  }

  return href + (link || '');
}

export default Service.extend({
  init() {
    this._super(...arguments);
    this.window = window;
  },
  /**
   * Wrapper for window.open
   * @see https://www.w3schools.com/jsref/met_win_open.asp
   */
  open() {
    return this.window.open(...arguments);
  },
  redirectTo(href) {
    this.window.location.href = parseURL(href);
  },
  replace(href) {
    this.window.location.replace(parseURL(href));
  },
  reload() {
    this.window.location.reload();
  },
  /**
   * Get the principal element
   * of the application
   * @returns {HTMLElement} Ember application element or document if not found
   */
  ownerElement() {
    return this.window.document.querySelector('.ember-application') || document;
  }
});
