import DS from 'ember-data';
import { computed } from '@ember/object';
import { alias, sort } from '@ember/object/computed';

export default DS.Model.extend({
  item: DS.belongsTo('item'),
  email: DS.attr('string'),
  pictureURL: '/avatar.png',
  requester: DS.belongsTo('user'),
  comment: DS.attr('string'),
  comments: DS.hasMany('comment'),
  ratings: DS.hasMany('rating'),
  deleteComment: DS.attr('boolean'),
  rating: DS.attr('number'),

  name: alias('email'),

  commentsOrdering: [ 'createdAt:desc' ],
  orderedComments:  sort('comments', 'commentsOrdering'),
  ratingsOrdering: [ 'createdAt:desc' ],
  orderedRatings:  sort('ratings', 'ratingsOrdering'),

  commentsAndRatings: computed('orderedComments', 'orderedRatings', function() {
    return this.orderedComments.concat(this.orderedRatings);
  }),
  orderedCommentsAndRatings:  sort('commentsAndRatings', 'ratingsOrdering'),
});
