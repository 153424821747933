import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default Route.extend({
  store: service(),

  async model() {
    let { userid } = this.paramsFor('users');
    return RSVP.hash({
      user: this.store.findRecord('user', userid),
      // TODO: currently only one template
      // This will have to retrieve the template
      // list from the survey api when starting
      // allowing customising templates.
      templates: Promise.resolve([{
        id: '60cbed08d74a711b170f064a',
        name: 'WorkCompass Standard'
      }]),
    });
  }
});
