import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default Controller.extend({
  window: service(),
  queryParams: ['redirectto'],
  redirectto: null,
  actions: {
    getCredentials() {
      if(this.get('adpCredentialsStatus.present') === false){
        later(() => {
          this.store.queryRecord('adp-credentials-status', {}).then((status) => {
            this.set('adpCredentialsStatus', status);
            this.send('getCredentials');
          })
        }, 5000);
      }
      else {
        this.window.redirectTo(this.redirectto);
      }
    }
  }
});
