define("frontend/helpers/can-manage", ["exports", "organization-settings/helpers/can-manage"], function (_exports, _canManage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _canManage.default;
    }
  });
  Object.defineProperty(_exports, "canManage", {
    enumerable: true,
    get: function get() {
      return _canManage.canManage;
    }
  });
  Object.defineProperty(_exports, "hasPermission", {
    enumerable: true,
    get: function get() {
      return _canManage.hasPermission;
    }
  });
});
