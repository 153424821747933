import DS from 'ember-data';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Item from './item';

export default Item.extend({
  description:       DS.attr('string'),
  dueDate:           DS.attr('date'),
  progressValue:     DS.attr('number', { defaultValue() { return 0; } }),
  progressUpdatedAt: DS.attr('date'),
  comments:          DS.hasMany('comment'),
  commentsOrdering:  [ 'createdAt:desc' ],
  changes:           DS.hasMany('change'),
  progressUpdates:   DS.hasMany('progress-update'),
  orderedComments:   sort('comments', 'commentsOrdering'),

  needsProgressUpdate: computed('progressUpdatedAt', function() {
    return this.dueDate <= (new Date()) && this.progressUpdatedAt < this.dueDate;
  }),

  labelStatus: service(),
  label: computed('', function() {
    return this.labelStatus.label(this);
  }),
});
