define("frontend/controllers/admin/users", ["exports", "organization-settings/controllers/admin/users"], function (_exports, _users) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _users.default;
    }
  });
});
