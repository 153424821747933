import DS from 'ember-data';
import { alias, equal, or } from '@ember/object/computed';

export default DS.Model.extend({
  section: DS.belongsTo('section', { inverse: 'items' }),
  feedbackRequests: DS.hasMany('feedback-request'),
  rating: DS.attr('number'),
  weight: DS.attr('number'),
  'approved?': DS.attr('boolean'),
  'removed?':  DS.attr('boolean'),

  itemType: alias('constructor.modelName'),
  isGoal: equal('itemType', 'goal'),
  isMultipleChoiceItem: equal('itemType', 'competency'),
  isFreeTextItem: equal('itemType', 'development-need'),
  isQuestionItem: equal('itemType', 'question'),
  isFreeTextOrQuestionItem: or('isFreeTextItem', 'isQuestionItem'),
});
