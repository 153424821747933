import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    return this.store.queryRecord('adp-credentials-status', {});
  },
  setupController(controller, model) {
    controller.set('adpCredentialsStatus', model);
    controller.send('getCredentials');
  }
});
