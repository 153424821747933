import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import moment from 'moment';

export default Controller.extend({
  showDateRangeModal: false,
  dateRangeSelected: false,
  displayError: false,
  exportBlocked: false,
  chartData: computed('model', function() {
    return {
      labels: ['No Plan', 'Needs Approval', 'Approved', 'Overdue', 'Completed'],
      datasets: [
        {
          label: 'Total',
          backgroundColor: 'rgb(69, 114, 167)',
          borderColor: 'rgb(69, 114, 167)',
          data: [
            this.model.noPlan, this.model.needsApproval, this.model.approved,
            this.model.overdue, this.model.completed
          ]
        }
      ]
    }
  }),
  userOrdering: [ 'staff_member.name' ],
  sortedNoPlanListOfNames: sort('model.noPlanListOfNames', 'userOrdering'),
  sortedNeedsApprovalListOfNames: sort('model.needsApprovalListOfNames', 'userOrdering'),
  sortedApprovedListOfNames: sort('model.approvedListOfNames', 'userOrdering'),
  sortedOverdueListOfNames: sort('model.overdueListOfNames', 'userOrdering'),
  sortedCompletedListOfName: sort('model.completedListOfNames', 'userOrdering'),

  chartOptions: computed('model', function(){
    var data = [
      this.sortedNoPlanListOfNames,
      this.sortedNeedsApprovalListOfNames,
      this.sortedApprovedListOfNames,
      this.sortedOverdueListOfNames,
      this.sortedCompletedListOfName,
    ]

    return {
      tooltips: {
        // Disable the on-canvas tooltip
        enabled: false,
        custom: function(tooltipModel) {
          var userAndPlanData = []
          if(tooltipModel['dataPoints']){
            userAndPlanData = data[tooltipModel['dataPoints'][0].index]
          }
          else {
            return;
          }

          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            var bodyLines = tooltipModel.body.map(getBody);
            var innerHtml = '<thead>';

            userAndPlanData.forEach(function(data) {
              if (!data['plan']) {
                innerHtml += '<tr><th>' + `<a class="status-report__uesr-link" href="/users/${data['staff_member'].id}">` + data['staff_member'].name + '</a>' + '</th></tr>'
              }
              else if (data['plan'].status == 'draft') {
                innerHtml += '<tr><th>' + `<a class="status-report__uesr-link" href="/app/plans/${data['plan'].id}">` + data['staff_member'].name + '</a>' + '</th></tr>'
              }
              else {
                innerHtml += '<tr><th>' + `<a class="status-report__uesr-link" href="/plans/${data['plan'].id}">` + data['staff_member'].name + '</a>' + '</th></tr>'
              }
            });
            innerHtml += '</thead><tbody>';

            bodyLines.forEach(function(body) {
              innerHtml += '<tr><td>' + '<div class="status-report__uesr-link">' + body + '</div>' + '</td></tr>';
            });
            innerHtml += '</tbody>';

            var tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          var position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.backgroundColor = 'black';
        }
      }
    }
  }),
  actions: {
    setCycle(value){
      if (value == 'range') {
        this.set('showDateRangeModal', true);
      }
      else {
        this.set('dateRangeSelected', false);
        this.transitionToRoute('insight.status-reports.status-report', value);
      }
    },
    selectDateRange(){
      this.set('showDateRangeModal', false);
      this.set('dateRangeSelected', true);
      let from = moment(this.from);
      let to = moment(this.to);
      let idRange = `${from.format('YYYY-MM-DD')}_${to.format('YYYY-MM-DD')}`
      this.store.queryRecord('cycle-status', { from: from.format('YYYY/MM/DD'), to: to.format('YYYY/MM/DD') })
      .then(() => {
        this.transitionToRoute('insight.status-reports.status-report', idRange);
      })
      .catch(() => {
        this.set('displayError', true);
      });
    },
    exportData(cycleId){
      this.warnAboutWaitTime(`/api/cycle-statuses/report?year=${cycleId}`);
    },
    exportDataRange(from, to){
      from = moment(from).format('DD/MM/YYYY');
      to = moment(to).format('DD/MM/YYYY');
      this.warnAboutWaitTime(`/api/cycle-statuses/report?from=${from}&to=${to}`);
    },
    exportHistoricData(){
      this.warnAboutWaitTime('/api/cycle-statuses/report');
    },
  },
  warnAboutWaitTime(url){
    let ok = confirm('This could take up to 5 minutes. Please do not refresh your browser during the process. Are you sure you want to continue?');
    if (ok) {
      this.set('exportBlocked', true);
      window.location = url;
      setTimeout(() => {
        this.set('exportBlocked', false);
      }, 3 * 60 * 1000);
    }
  }
});
