import DS from 'ember-data';
import { alias } from '@ember/object/computed';

export default DS.Model.extend({
  name:     DS.attr('string'),
  photo:    DS.attr('file'),
  photoUrl: alias('photo.url'),
  deletePhoto:    DS.attr('boolean', { defaultValue: false }),
  email:          DS.attr('string'),
  authorisation:  DS.attr('string', { defaultValue: 'employee' }),
  technicalAdmin: DS.attr('boolean', { defaultValue: false }),
  jobTitle:       DS.attr('string'),
  jobDescription: DS.attr('string'),
  bio:            DS.attr('string'),
  department:     DS.attr('string'),
  employeeNumber: DS.attr('string'),
  location:       DS.attr('string'),
  function:       DS.attr('string'),

  staffMembers: DS.hasMany('user', { defaultValue: [], inverse: 'manager' }),
  manager:      DS.belongsTo('user', { inverse: 'staffMembers', defaultValue: null }),
  division:     DS.belongsTo('division', { inverse: 'users', defaultValue: null }),
  additionalReviewers: DS.hasMany('user', { defaultValue: [] }),
  managedDivisions:    DS.hasMany('division', { inverse: 'divisionalAdmins',  defaultValue: [] }),
  isEmailFieldDisabled: DS.attr('boolean'),
  isEmployeeNumberFieldDisabled: DS.attr('boolean'),
  isDepartmentFieldDisabled: DS.attr('boolean'),
  isDivisionFieldDisabled: DS.attr('boolean'),
  isNameFieldDisabled: DS.attr('boolean'),
  isManagerDropdownDisabled: DS.attr('boolean'),
  isAuthorisationDropdownDisabled: DS.attr('boolean'),
});
