define("frontend/components/cycle-calendar", ["exports", "wc-calendar/components/cycle-calendar"], function (_exports, _cycleCalendar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cycleCalendar.default;
    }
  });
});
