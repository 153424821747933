import Service, { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { camelize } from '@ember/string';
import { isEmpty } from '@ember/utils';
import { default as ValueParser }  from '../helpers/value-parser';
/**
 * This service provide application configuration
 * (also know as `organisation-setting`) provided by the backend.
 * It respond under the `setting` model formatted in a category/key/value.
 */
export default Service.extend({
  // Services
  store: service(),

  /**
   * Property to keep track of last
   * time settings has been pull from API
   * and ensure that computed property
   * recompute
   */
  lastReload: Date.now(),

  /**
   * Return setting's value based on
   * a category and a key. Optionally if no
   * value found return optional default value.
   * @param {String} category
   * @param {String} key
   * @param {String?} defaultValue
   * @returns {*}
   */
  getValue(category, key, defaultValue = null) {
    if (isEmpty(category) || isEmpty(key)) throw new Error('Category and Key are required.');

    const setting = this.load_and_convert(category)[camelize(key.toLowerCase())];

    return (isEmpty(setting)) ? defaultValue : setting;
  },

  /**
   * Load and convert a specific
   * setting category to
   * an object key/value
   * @param {String} category
   * @returns {{*}}
   */
  load_and_convert(category) {
    let settings = {};

    (this.store.peekAll('setting') || [])
    .filter(setting => setting.category === category)
    .forEach(setting => {
      settings[camelize(setting.key.toLowerCase())] = this.parse_value(setting.value);
    });

    return settings;
  },

  /**
   * Parse string to true
   * data type
   * @param {String} value
   * @returns {*}
   */
  parse_value(value) {
    if (isEmpty(value))  return null;

    return new ValueParser(value).convert();
  },

  // Properties

  /**
   * Auth (short for Authentication) provide configuration
   * related to authentication such as which authentication type is enabled.
   */
  auth: computed('lastReload', function() {
    let authSettings = this.load_and_convert('auth')

    authSettings.needLogin = !authSettings.ssoEnabled;

    return authSettings;

  }),

  /**
   * Return all the settings for category
   * LEGACY
   * @returns {[*]}
   */
  legacy: computed('lastReload', function(){
    return this.load_and_convert('LEGACY');
  }),

  /**
   * Return all the settings for category
   * RATINGS.
   */
  ratings: computed('lastReload', function() {
    return this.load_and_convert('RATINGS');
  }),

  /**
   * Return all the settings for category
   * planning.
   */
  planning: computed('lastReload', function() {
    return this.load_and_convert('PLANNING');
  }),

  /**
   * Return all the settings for category
   * third party filters
   * @returns {[*]}
   */
  tp_filters: computed('lastReload', function(){
    return this.load_and_convert('TP_FILTERS');
  }),

  /**
   * Return all the settings for category
   * REPORT_CALIBRATION
   * @returns {[*]}
   */
    reportCalibration: computed('lastReload', function(){
      return this.load_and_convert('REPORT_CALIBRATION');
    }),

  /**
   * Update a key registry
   * @param {string}  key       Key name of the setting
   * @param {string}  value     New value to update with
   * @param {string}  category  Setting's category
   */
  async update(key, value, category) {
    let setting = (this.store.peekAll('setting') || [])
      .find(setting => setting.key === key);

    if(setting) {
      setting.set('value', value);
    } else {
      setting = this.store.createRecord('setting', {
        key,
        value,
        category,
      });
    }

    await setting.save();

    // Reload all settings
    await this.store.findAll('setting');
    // Ensure tall all computed listening
    // trigger recompute by updating the
    // lastReload property.
    this.set('lastReload', Date.now());
  },
});
