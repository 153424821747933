import DS from 'ember-data';
import { computed } from '@ember/object';
import { sort, filterBy } from '@ember/object/computed';

export default DS.Model.extend({
  plan:          DS.belongsTo('plan'),
  items:         DS.hasMany('item', { polymorphic: true }),
  ratingOptions: DS.hasMany('rating-option'),

  name:    DS.attr('string'),
  ordinal: DS.attr('number'),
  type:    DS.attr('string'),
  weight:  DS.attr('number'),

  ratingOptionsOrdering: [ 'score:asc' ],
  orderedRatingOptions:  sort('ratingOptions', 'ratingOptionsOrdering'),

  itemsOrdering:  ['ordinal'],
  orderedItems:   sort('items', 'itemsOrdering'),
  approvedItems:  filterBy('orderedItems', 'approved?'),

  averageRating: computed('items.@each.{rating,weight}', function() {
    const ratedItems = this.items.filter(i => i.get('rating') !== null && i.get('rating') !== undefined);

    if (ratedItems.length === 0) {
      return undefined;
    }

    let sectionHasWeights = ratedItems.some(i => i.get('weight') !== null && i.get('weight') !== undefined);

    let totalRatings = ratedItems.reduce(function(acc, i) {
      let rating = parseInt(i.get('rating'));
      if (sectionHasWeights) {
        rating *= i.get('weight');
      }
      return acc + rating;
    }, 0);

    if (sectionHasWeights) {
      let ratedItemWeights = ratedItems.reduce((acc, i) => acc + i.get('weight'), 0);
      return totalRatings / ratedItemWeights;
    } else {
      return totalRatings / ratedItems.length;
    }
  }),
  hasRatings: computed('ratingOptions', function() {
    return this.get('ratingOptions.length') != 0;
  }),
});
